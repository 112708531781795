/*@tailwind preflight;*/

.bg-transparent {
  background-color: transparent
}

.bg-black {
  background-color: #22292F
}

.bg-white {
  background-color: #FFFFFF
}

.bg-gray-100 {
  background-color: #f7fafc
}

.bg-gray-200 {
  background-color: #edf2f7
}

.bg-gray-700 {
  background-color: #4a5568
}

.bg-blue {
  background-color: #3490DC
}

.bg-green-light {
  background-color: #51D88A
}

.bg-blue-dark {
  background-color: #2779BD
}

.bg-blue-lightest {
  background-color: #EFF8FF
}

.hover\:bg-gray-100:hover {
  background-color: #f7fafc
}

.hover\:bg-green-dark:hover {
  background-color: #1F9D55
}

.hover\:bg-blue-dark:hover {
  background-color: #2779BD
}

.border-gray-300 {
  border-color: #e2e8f0
}

.border-gray-400 {
  border-color: #cbd5e0
}

.border-gray-700 {
  border-color: #4a5568
}

.hover\:border-black:hover {
  border-color: #22292F
}

.hover\:border-blue-lighter:hover {
  border-color: #BCDEFA
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-12 {
  border-radius: 1.2rem
}

.rounded-none {
  border-radius: 0
}

.rounded {
  border-radius: .4rem
}

.rounded-lg {
  border-radius: .8rem
}

.border-dashed {
  border-style: dashed
}

.border {
  border-width: 1px
}

.border-r-1 {
  border-right-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.flex {
  display: -webkit-flex;
  display: flex
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  -webkit-flex-direction: row;
          flex-direction: row
}

.flex-row-reverse {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse
}

.flex-col {
  -webkit-flex-direction: column;
          flex-direction: column
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap
}

.items-start {
  -webkit-align-items: flex-start;
          align-items: flex-start
}

.items-end {
  -webkit-align-items: flex-end;
          align-items: flex-end
}

.items-center {
  -webkit-align-items: center;
          align-items: center
}

.self-center {
  -webkit-align-self: center;
          align-self: center
}

.justify-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start
}

.justify-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end
}

.justify-center {
  -webkit-justify-content: center;
          justify-content: center
}

.justify-between {
  -webkit-justify-content: space-between;
          justify-content: space-between
}

.content-center {
  -webkit-align-content: center;
          align-content: center
}

.flex-1 {
  -webkit-flex: 1 1 0%;
          flex: 1 1 0%
}

.flex-auto {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto
}

.flex-grow-0 {
  -webkit-flex-grow: 0;
          flex-grow: 0
}

.flex-shrink-0 {
  -webkit-flex-shrink: 0;
          flex-shrink: 0
}

.flex-shrink {
  -webkit-flex-shrink: 1;
          flex-shrink: 1
}

.font-600 {
  font-weight: 600
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-32 {
  height: 3.2rem
}

.h-40 {
  height: 4rem
}

.h-48 {
  height: 4.8rem
}

.h-52 {
  height: 5.2rem
}

.h-64 {
  height: 6.4rem
}

.h-72 {
  height: 7.2rem
}

.h-128 {
  height: 12.8rem
}

.h-400 {
  height: 40rem
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.m-4 {
  margin: 0.4rem
}

.m-8 {
  margin: 0.8rem
}

.m-12 {
  margin: 1.2rem
}

.m-24 {
  margin: 2.4rem
}

.m-auto {
  margin: auto
}

.my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.my-6 {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mt-0 {
  margin-top: 0
}

.mb-1 {
  margin-bottom: 0.25rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.ml-5 {
  margin-left: 1.25rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.ml-24 {
  margin-left: 2.4rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mt-64 {
  margin-top: 6.4rem
}

.-mt-2 {
  margin-top: -0.5rem
}

.-mb-4 {
  margin-bottom: -0.4rem
}

.-ml-12 {
  margin-left: -1.2rem
}

.-mt-24 {
  margin-top: -2.4rem
}

.-ml-80 {
  margin-left: -8rem
}

.max-w-400 {
  max-width: 40rem
}

.max-w-xs {
  max-width: 32rem
}

.max-w-sm {
  max-width: 48rem
}

.max-w-md {
  max-width: 64rem
}

.max-w-lg {
  max-width: 80rem
}

.max-w-xl {
  max-width: 96rem
}

.max-w-2xl {
  max-width: 112rem
}

.min-h-48 {
  min-height: 4.8rem
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-xs {
  min-width: 32rem
}

.min-w-md {
  min-width: 64rem
}

.opacity-25 {
  opacity: 0.25
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: 0.25rem
}

.p-4 {
  padding: 0.4rem
}

.p-5 {
  padding: 1.25rem
}

.p-8 {
  padding: 0.8rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.p-32 {
  padding: 3.2rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.py-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem
}

.pl-0 {
  padding-left: 0
}

.pr-4 {
  padding-right: 0.4rem
}

.pr-5 {
  padding-right: 1.25rem
}

.pl-5 {
  padding-left: 1.25rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pr-8 {
  padding-right: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pr-12 {
  padding-right: 1.2rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pl-12 {
  padding-left: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pl-20 {
  padding-left: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pr-24 {
  padding-right: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pb-40 {
  padding-bottom: 4rem
}

.pl-40 {
  padding-left: 4rem
}

.pr-48 {
  padding-right: 4.8rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pt-64 {
  padding-top: 6.4rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pt-72 {
  padding-top: 7.2rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.pb-160 {
  padding-bottom: 16rem
}

.pb-320 {
  padding-bottom: 32rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.shadow-0 {
  box-shadow: none
}

.shadow {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10)
}

.shadow-md {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)
}

.shadow-none {
  box-shadow: none
}

.hover\:shadow-0:hover {
  box-shadow: none
}

.hover\:shadow-none:hover {
  box-shadow: none
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-white {
  color: #FFFFFF
}

.text-gray-500 {
  color: #a0aec0
}

.text-gray-700 {
  color: #4a5568
}

.text-gray-900 {
  color: #1a202c
}

.text-red {
  color: #E3342F
}

.text-orange {
  color: #F6993F
}

.text-blue {
  color: #3490DC
}

.text-red-light {
  color: #EF5753
}

.text-green-light {
  color: #51D88A
}

.text-blue-light {
  color: #6CB2EB
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-16 {
  font-size: 1.6rem
}

.text-20 {
  font-size: 2rem
}

.text-lg {
  font-size: 1.8rem
}

.text-xl {
  font-size: 2rem
}

.text-2xl {
  font-size: 2.4rem
}

.text-3xl {
  font-size: 3rem
}

.text-4xl {
  font-size: 3.6rem
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.align-middle {
  vertical-align: middle
}

.visible {
  visibility: visible
}

.whitespace-no-wrap {
  white-space: nowrap
}

.w-16 {
  width: 1.6rem
}

.w-24 {
  width: 2.4rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-xs {
  width: 32rem
}

.w-xl {
  width: 96rem
}

.w-2xl {
  width: 112rem
}

.w-1\/4 {
  width: 25%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-9999 {
  z-index: 9999
}

@media (min-width: 600px) {

  .sm\:flex {
    display: -webkit-flex;
    display: flex
  }

  .sm\:p-32 {
    padding: 3.2rem
  }
}

@media (min-width: 960px) {

  .md\:w-full {
    width: 100%
  }
}

@media (min-width: 1280px) {

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pl-0 {
    padding-left: 0
  }
}
